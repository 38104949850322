<template>
  <div class="module" v-show="show">
    <slot :data="result" :empty='empty' :returnSuccess='returnSuccess' :errorMsg='errorMsg' :loading="loading" :totalResult="totalResult"></slot>
    <loading :options="loadOption" v-if="showLoading && loading"></loading>
    <div class="empty" v-if="empty">
      <img src="@/assets/img/common/icon36.png" alt="" class="focus_img">
      <div>{{emptyMsg}}，<a @click="toAddAddress" href="javascript:;" class="toAddAddress">点击添加</a></div>
    </div>
    <div class="empty" v-if="!returnSuccess">
      <img src="@/assets/img/common/icon36.png" alt="" class="focus_img">
      <div>{{errorMsg}}</div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from "vue";
  import Loading from "../UI/Loading/index";

  import usePageScroll, { PropsType } from "@/hooks/usePageScroll";
  import Modal from "@/components/UI/Modal";
  import router from "@/router";

  export default defineComponent({
    props: {
      filterFunc: Function,
      url: String,
      params: Object,
      show: {
        type: Boolean,
        default: false,
      },
      showLoading: {
        type: Boolean,
        default: false,
      },
      emptyMsg: {
        type: String,
        default: '暂无数据',
      }
    },
    components: {
      Loading,
    },
    setup(props) {
      const { loading, result , empty,returnSuccess,errorMsg, totalResult } = usePageScroll(props as PropsType);
      const loadOption = {
        text: "加载中...",
        color: "#df0024",
        textColor: "#df0024",
      };
      function  toAddAddress(){
        router.push({
          name: "addAddressOfUser",
        });
      }
      return {
        result,
        loading,
        loadOption,
        empty,
        returnSuccess,
        errorMsg,
        totalResult,
        toAddAddress
      };
    },
  });
</script>


<style lang="scss" scoped>
  .module {
    margin-bottom: 130px;
  }
  .empty {
    padding-top: 160px;
    text-align: center;
    padding-bottom: 50px;
    color: #9f9f9f;
    font-size: 14px;
  }
  .empty img {
    width: 132px;
    height: 104px;
    display: block;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .toAddAddress{
    color: #df0024;
  }
</style>
