
  import { defineComponent, PropType } from "vue";
  import Loading from "../UI/Loading/index";

  import usePageScroll, { PropsType } from "@/hooks/usePageScroll";
  import Modal from "@/components/UI/Modal";
  import router from "@/router";

  export default defineComponent({
    props: {
      filterFunc: Function,
      url: String,
      params: Object,
      show: {
        type: Boolean,
        default: false,
      },
      showLoading: {
        type: Boolean,
        default: false,
      },
      emptyMsg: {
        type: String,
        default: '暂无数据',
      }
    },
    components: {
      Loading,
    },
    setup(props) {
      const { loading, result , empty,returnSuccess,errorMsg, totalResult } = usePageScroll(props as PropsType);
      const loadOption = {
        text: "加载中...",
        color: "#df0024",
        textColor: "#df0024",
      };
      function  toAddAddress(){
        router.push({
          name: "addAddressOfUser",
        });
      }
      return {
        result,
        loading,
        loadOption,
        empty,
        returnSuccess,
        errorMsg,
        totalResult,
        toAddAddress
      };
    },
  });
